.layout__header {
    position: relative;
    z-index: 20;
}

.layout__header-row {
    height: 64px;
}

.ant-typography.layout__title {
    margin: 0;
    color: #fff;
}

.layout__container {
    padding: 1rem;
    margin: 1rem;
}

.layout__user {
    margin-right: 1rem;
    color: #fff;
}

.ant-layout-sider.layout__menu {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding-top: 64px;
    z-index: 10;
}

@media only screen and (max-width: 600px) {
    .layout__container {
        margin: 0;
    }
}

#map { height: 480px; }
